import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';

// Constants
import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';

// Types
import type { AppDispatch } from '~/reducers/index';
import type { UserContext } from '@zola-helpers/client/dist/es/@types';

// Utils
import { getCategories, getWeddingShopCategories } from '~/actions/NavActions';
import { getBoutiqueNavItems } from '@zola-helpers/client/dist/es/nav/boutiqueNavData';
import {
  getRegistryNavItems,
  getRegistryQuickLinks,
  getRegistrySecondaryNavItems,
} from '@zola-helpers/client/dist/es/nav/registryNavData';
import { isPostAuthNav3Mobile } from '~/components/navV3/PostAuth/utils';
import experimentFlags, { HOLIDAY_SHOPS } from '~/util/experimentFlags';

const {
  EVENTS: { INIT_NAV_DATA },
} = NAV_DATA;

/**
 * Emits INIT_NAV_DATA for the mweb post-auth nav on web-zola pages that need secondary nav,
 * tertiary nav, and/or quick links. See useNavV1Methods.js for desktop implementation.
 */
const useInitWebZolaNavData = (userContext: UserContext): void => {
  const dispatch = useDispatch<AppDispatch>();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (initialized || _isEmpty(userContext) || !isPostAuthNav3Mobile(userContext)) return;
    window.zolaNav = window.zolaNav || {};
    window.zolaNav.setSecondaryNavType = (type: string) => {
      if (!type) return undefined;

      // Emits INIT_NAV_DATA for web-zola boutique pages
      if (type === 'wedding-shop') {
        dispatch(getWeddingShopCategories())
          .then((weddingShopResponse) => {
            const categoryData = getBoutiqueNavItems(userContext, weddingShopResponse);
            window.dispatchEvent(
              new CustomEvent(INIT_NAV_DATA, {
                detail: {
                  userContext,
                  secondaryData: [],
                  tertiaryData: categoryData,
                },
              })
            );
            setInitialized(true);
          })
          .catch(() => undefined);
        return undefined;
      }

      // Emits INIT_NAV_DATA for web-zola registry pages
      dispatch(getCategories())
        .then((categoriesResponse) => {
          const showHolidayShopNav = experimentFlags.getFlag(HOLIDAY_SHOPS, userContext);
          const registryCategories = getRegistryNavItems(
            userContext,
            categoriesResponse,
            showHolidayShopNav
          );
          const secondaryData = getRegistrySecondaryNavItems(userContext);
          const registrySlug = userContext?.has_registry
            ? userContext?.user_role_account_weddings?.[0].slug
            : undefined;
          const quickLinks = getRegistryQuickLinks(registrySlug);
          window.dispatchEvent(
            new CustomEvent(INIT_NAV_DATA, {
              detail: {
                userContext,
                secondaryData,
                tertiaryData: registryCategories,
                quickLinks,
              },
            })
          );
          setInitialized(true);
        })
        .catch(() => undefined);
      return undefined;
    };

    // Listens for whether a window property has been set to trigger the INIT_NAV_DATA event accordingly
    if (window.zolaNav.secondaryNavType) {
      window.zolaNav.setSecondaryNavType(window.zolaNav.secondaryNavType);
    }
  }, [dispatch, initialized, userContext]);
};

export default useInitWebZolaNavData;
