import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';

import SearchIcon from '~/components/navV3/iconsV3/Search';
import COLORS from '@zola/zola-ui/src/styles/emotion/colors3';
import FONT from '@zola/zola-ui/src/styles/emotion/fonts';
import MEDIA_QUERY from '@zola/zola-ui/src/styles/emotion/mediaQuery';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';
import utils from '@zola/zola-ui/src/styles/emotion/utils';

import type { MobileNavMenuProps } from './MobileNavMenu';

const MOBILE_XS_WIDTH = '320px';
const MOBILE_WIDTH = '427px';
const TABLET_WIDTH = '468px';

const slideIn = (endPosition = '0%'): string => keyframes`
  100% { transform: translateX(${endPosition}); }
`;

const slideOut = (startPostion = '0%', endPosition = '-100%'): string => keyframes`
  0% { transform: translateX(${startPostion}); }
  100% { transform: translateX(${endPosition}); }
`;

const SlideInOutAnimation = (isMenuOpen: boolean) => css`
  animation: ${isMenuOpen ? slideIn() : slideOut('0', `-${MOBILE_XS_WIDTH}`)} 0.5s forwards;
  ${MEDIA_QUERY.EXCLUDE_MOBILE_XS} {
    animation: ${isMenuOpen ? slideIn() : slideOut('0', `-${MOBILE_WIDTH}`)} 0.5s forwards;
  }
  ${MEDIA_QUERY.TABLET} {
    animation: ${isMenuOpen ? slideIn() : slideOut('0', `-${TABLET_WIDTH}`)} 0.5s forwards;
  }
`;

type PickedMobileNavProps = Pick<MobileNavMenuProps, 'isMenuOpen'>;

export const Container = styled('div')<PickedMobileNavProps>`
  background-color: ${COLORS.WHITE_100};
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  padding: 56px ${SPACING.S24} ${SPACING.MD};
  max-width: ${MOBILE_XS_WIDTH};
  transform: translateX(-${MOBILE_XS_WIDTH});
  ${MEDIA_QUERY.EXCLUDE_MOBILE_XS} {
    max-width: ${MOBILE_WIDTH};
    transform: translateX(-${MOBILE_WIDTH});
  }
  ${MEDIA_QUERY.TABLET} {
    max-width: ${TABLET_WIDTH};
    transform: translateX(-${TABLET_WIDTH});
  }
  ${utils.concealScrollbars}
  a:hover {
    text-decoration: none;
  }
  ${({ isMenuOpen }) => typeof isMenuOpen !== 'undefined' && SlideInOutAnimation(isMenuOpen)}
`;

export const Overlay = styled('div')<PickedMobileNavProps>`
  background-color: rgba(0, 0, 0, 0.5);
  display: ${(p) => (p.isMenuOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  outline: 0;
`;

export const SearchButton = styled.button`
  background-color: ${COLORS.BLACK_005};
  padding: ${SPACING.SM} ${SPACING.S16};
  width: 100%;
  border-radius: 100px;
  display: flex;
  align-items: center;
  color: ${COLORS.BLACK_050};
  border: none;
  margin-bottom: 16px;
  ${FONT.textSizeCompact(FONT.SIZE.SMALLER)};
`;

export const StyledSearchIcon = styled(SearchIcon)`
  color: ${COLORS.BLACK_100};
  margin-right: ${SPACING.S16};
`;

export const bottomBorder = css`
  border-bottom: 1px solid ${COLORS.BLACK_010};
`;

export const Close = styled.button`
  background: transparent;
  position: absolute;
  border: none;
  right: 18px;
  top: 18px;
  padding: 0;
  height: 24px;
  width: 24px;
  color: ${COLORS.BLACK_100};
`;

export const FindCoupleWrapper = styled.div`
  padding: ${SPACING.S24} 0;
`;

export const BottomLinksWrapper = styled.div`
  margin: ${SPACING.S24} 0;
  display: flex;
  flex-direction: column;
  gap: ${SPACING.S16};
`;
