import React from 'react';

import { trackNavigationClicked } from '@zola/tracking-contracts/src/tracking';
import { transformNavigationLevel } from '~/util/trackNavigationClickedHelpersV2';

import type { PrimaryNavItemType, PrimaryNavType } from '../../../preauthNavData';

import {
  Container,
  liBaseStyles,
  StyledLinkV2,
  UnorderedList,
} from './MobileNavMenuLinkGroup.styles';

export type MobileNavMenuLinkGroupsProps = {
  linkGroups: PrimaryNavType;
  className?: string;
  trackingIndexOffset?: number;
};

const MobileNavMenuLinkGroup = ({
  linkGroups,
  className,
  trackingIndexOffset = 0,
}: MobileNavMenuLinkGroupsProps): JSX.Element => {
  const handleTrackNavigationClicked = (group: PrimaryNavItemType, groupIndex: number) => {
    trackNavigationClicked({
      business_category: group.businessCategory || 'UNATTRIBUTED',
      business_unit: group.businessUnit || 'UNATTRIBUTED',
      navigation_type: 'GLOBAL',
      navigation_level_1: transformNavigationLevel(group.label),
      navigation_level_1_position: trackingIndexOffset + groupIndex + 1,
    });
  };

  return (
    <Container className={className}>
      <UnorderedList>
        {linkGroups.nav.map((group, groupIndex) => (
          <li key={groupIndex} css={liBaseStyles}>
            <StyledLinkV2
              href={group.path}
              noTextTransform
              noUnderline
              onClick={() => handleTrackNavigationClicked(group, groupIndex)}
              sizes="small"
              subtle
            >
              {group.icon} {group.label}
            </StyledLinkV2>
          </li>
        ))}
      </UnorderedList>
    </Container>
  );
};

export default MobileNavMenuLinkGroup;
