import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import loadable from '@loadable/component';
import { useForceUpdateOnWindowResize } from '@zola/zola-ui/src/hooks/useForceUpdateOnWindowResize';
import { desktopOnly, excludeDesktop } from '@zola/zola-ui/src/styles/emotion/mixins/responsive';
import { useAccountIncentivizationCheck } from '@zola/zola-ui/src/hooks/useAccountIncentivizationCheck/useAccountIncentivizationCheck';

import { getUserContext } from '~/selectors/user';
import { usePostAuthDesktopNavRolloutModal } from '~/components/common/modals/PostAuthDesktopNavRolloutModal/usePostAuthDesktopNavRolloutModal';
import { useBookingModal } from '~/components/common/modals/BookingModal/hooks/useBookingModal';
import { useExposeAuthModal } from '~/hooks/useExposeAuthModal';
import { useGiftGiverReminderModal } from '~/components/common/modals/GiftGiverReminderModal/useGiftGiverReminderModal';
import { GiftGiverReminderModal } from '~/components/common/modals/GiftGiverReminderModal/GiftGiverReminderModal';
import ZolaGladApp from '~/components/GladlyChat/ZolaGladApp';
import WhichMobileNavToRender from '~/components/WhichMobileNavToRender';

import '../../../stylesheets/nav/WebsiteNav.less';

const NavV2 = loadable(() => import(/* webpackChunkName: "desktop-nav" */ '~/components/navV2'), {
  ssr: false,
});
const NavV3 = loadable(
  () =>
    import(/* webpackChunkName: "desktop-nav-v3" */ '~/components/navV3/desktop/DesktopWrapper'),
  { ssr: true }
);

/**
 * TODO:
 * Move Homestore into a separate Api route/entry point... actually this should live completely in web-store
 * Move Baby-nav into a separate Api route/entry point... actually this should live completely in web-baby
 * Move Organization JSON-LD to base of Next-apps... see orgShcema.jsx
 *
 */

const NavContainer = () => {
  useForceUpdateOnWindowResize();
  const userContext = useSelector(getUserContext);

  usePostAuthDesktopNavRolloutModal(userContext);
  useBookingModal(userContext);
  useExposeAuthModal();
  const { registryObjectId, showGiftGiverReminderModal, setShowGiftGiverReminderModal } =
    useGiftGiverReminderModal({
      userContext,
    });

  /**
   * PE-862: For pre-auth users, who have been offered an incentivization to _do something_
   * on zola (like make an inquiry and mark it booked to get 65% of save the dates) we
   * record in local storage that the incentive was seen.  If/when the browser has an
   * associated account, this activates the incentive for the account and clears local storage
   */
  useAccountIncentivizationCheck(userContext.object_id ? userContext : null);

  const showNavV3 = userContext.is_guest || userContext.is_baby_registry_only;
  const Nav = showNavV3 ? NavV3 : NavV2;

  return (
    <Fragment>
      <div css={excludeDesktop}>
        <WhichMobileNavToRender />
      </div>
      <div css={desktopOnly}>
        <Nav />
      </div>
      {registryObjectId && showGiftGiverReminderModal ? (
        <GiftGiverReminderModal
          onClose={() => setShowGiftGiverReminderModal(false)}
          registryObjectId={registryObjectId}
        />
      ) : null}
      <ZolaGladApp />
    </Fragment>
  );
};

export default NavContainer;
