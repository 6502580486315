import React from 'react';
import icon from '~/assets/images/Notifications/placeholder.svg';
import styles from './DefaultNotificationPanel.module.less';

const DefaultNotificationPanel: React.FC = () => {
  return (
    <div className={styles.panel}>
      <div>
        <img src={icon} alt="" aria-hidden />
        <h3>You’re all caught up</h3>
      </div>
    </div>
  );
};

export default DefaultNotificationPanel;
