import React, { Fragment, useMemo, useState } from 'react';

import { trackNavigationClicked } from '@zola/tracking-contracts/src/tracking';
import { transformNavigationLevel } from '~/util/trackNavigationClickedHelpersV2';
import { getHomestoreHref } from '~/util/getHomestoreHref';
import { getBabyHref } from '~/util/getBabyHref';
import featureFlags from '~/util/featureFlags';
import { useEffectOnce } from '@zola/zola-ui/src/hooks/useEffectOnce';

import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';

import { useCurrentVendorSearchedLocation } from '~/hooks/useCurrentVendorSearchedLocation';
import CloseIcon from '../../iconsV3/CloseIcon';
import {
  Container,
  Overlay,
  Close,
  SearchButton,
  StyledSearchIcon,
  bottomBorder,
  FindCoupleWrapper,
  BottomLinksWrapper,
} from './MobileNavMenu.styles';

import { getPrimaryPlan, primaryAdvice } from '../../preauthNavData';
import MobileNavMenuLinkGroup from './MobileNavMenuLinkGroup';
import SignUpLogin from './SignUpLogin';

export type MobileNavMenuProps = {
  isMenuOpen?: boolean;
  toggleSearch: () => void;
  toggleMenu: () => void;
};

const MobileNavMenu = ({
  isMenuOpen,
  toggleSearch,
  toggleMenu,
}: MobileNavMenuProps): JSX.Element => {
  const { searchedLocation } = useCurrentVendorSearchedLocation();
  const [seatingChartV2Released, setSeatingChartV2Released] = useState<boolean>(false);
  const findACouple = 'Find a couple';
  const primaryPlan = useMemo(
    () =>
      getPrimaryPlan({
        isMobile: true,
        location: searchedLocation,
        seatingChartV2Released,
      }),
    [searchedLocation, seatingChartV2Released]
  );

  const handleTrackNavigationClicked = () => {
    trackNavigationClicked({
      business_category: 'UNATTRIBUTED',
      business_unit: 'UNATTRIBUTED',
      navigation_level_1: transformNavigationLevel(findACouple),
      navigation_level_1_position: primaryPlan.nav.length + primaryAdvice.nav.length + 1,
      navigation_type: 'GLOBAL',
    });
  };

  useEffectOnce(() => {
    setSeatingChartV2Released(featureFlags.get('seatingChartV2Released'));
  });

  return (
    <Fragment>
      <Overlay isMenuOpen={isMenuOpen} onClick={toggleMenu} />
      <Container isMenuOpen={isMenuOpen}>
        <Close aria-label="Close" type="button" onClick={toggleMenu}>
          <CloseIcon width={20} height={20} showTitle={false} />
        </Close>
        <div css={bottomBorder}>
          <SearchButton onClick={toggleSearch}>
            <StyledSearchIcon height={20} width={20} />
            Find gifts, vendors, couples...
          </SearchButton>
        </div>
        <div css={bottomBorder}>
          <SignUpLogin />
        </div>
        <MobileNavMenuLinkGroup css={bottomBorder} linkGroups={primaryPlan} />
        <MobileNavMenuLinkGroup
          css={bottomBorder}
          linkGroups={primaryAdvice}
          trackingIndexOffset={primaryPlan.nav.length}
        />
        <FindCoupleWrapper css={bottomBorder}>
          <LinkV2
            href="/search/wedding-registry"
            noTextTransform
            noUnderline
            onClick={handleTrackNavigationClicked}
            subtle
          >
            {findACouple}
          </LinkV2>
        </FindCoupleWrapper>
        <BottomLinksWrapper>
          <LinkV2
            href={getBabyHref(featureFlags.getEnvironment())}
            inline
            noUnderline
            sizes="smaller"
            subtle
          >
            Zola Baby
          </LinkV2>
          <LinkV2
            href={getHomestoreHref(featureFlags.getEnvironment())}
            inline
            noUnderline
            sizes="smaller"
            subtle
          >
            Zola Home
          </LinkV2>
          <LinkV2
            href="/for-vendors"
            inline
            noUnderline
            rel="noopener noreferrer"
            sizes="smaller"
            subtle
            target="_blank"
          >
            Zola for vendors
          </LinkV2>
        </BottomLinksWrapper>
      </Container>
    </Fragment>
  );
};

export default MobileNavMenu;
