import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';
import { trackNavigationClicked } from '@zola/tracking-contracts/src/tracking';
import { transformNavigationLevel } from '~/util/trackNavigationClickedHelpersV2';

import { showModal } from '~/actions/ModalActions';
import { RootState } from '~/reducers/index';
import { getUserContext } from '~/selectors/user';
import signupLinkCallback from '~/components/common/auth/AuthModal/helpers/signupLinkCallback';
import MobileNavContext from '~/components/MobileNavV3/MobileNavContext';

import { Container } from './SignUpLogin.styles';

const {
  EVENTS: { MODAL_TYPE_AUTH },
} = NAV_DATA;

/**
 * Mobile Nav Menu Signup / Login buttons
 */
const SignUpLogin = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const userContext = useSelector((state: RootState) => getUserContext(state));
  const { primaryLinkId } = useContext(MobileNavContext);
  const { is_guest: isGuest } = userContext;
  const getStarted = 'Get started';
  const logIn = 'Log in';

  const handleTrackNavigationClicked = (title: string, position: number) => {
    trackNavigationClicked({
      business_category: 'UNATTRIBUTED',
      business_unit: 'UNATTRIBUTED',
      navigation_level_1: transformNavigationLevel(title),
      navigation_level_1_position: position,
      navigation_type: 'GLOBAL',
    });
  };

  const onClickSignUp = () => {
    handleTrackNavigationClicked(getStarted, 2);
    signupLinkCallback(primaryLinkId);
  };

  const onClickLogin = () => {
    handleTrackNavigationClicked(logIn, 1);
    dispatch(
      showModal(
        MODAL_TYPE_AUTH,
        {
          authState: 'login',
          signupLinkCallback: () => signupLinkCallback(primaryLinkId),
        },
        { size: 'sm', ariaLabel: 'Log In', v2Styles: true }
      )
    );
  };

  if (!isGuest) return null;
  return (
    <Container>
      <ButtonV3 onClick={() => onClickLogin()} variant="secondary">
        {logIn}
      </ButtonV3>
      <ButtonV3 onClick={() => onClickSignUp()}>{getStarted}</ButtonV3>
    </Container>
  );
};

export default SignUpLogin;
