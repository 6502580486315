import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';

import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { CheckboxField } from '@zola/zola-ui/src/components/Form/CheckboxField';
import FinalFormInput from '@zola/zola-ui/src/components/Form/inputV3/FinalFormInput';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import ModalV2 from '@zola/zola-ui/src/components/Modal/ModalV2';
import ModalContent from '@zola/zola-ui/src/components/Modal/ModalContent/ModalContent';
import { GiftAddIcon } from '@zola/zola-ui/src/components/SvgIconsV3/GiftAdd';
import H from '@zola/zola-ui/src/typography/Headings';

import type { WCreateGiftGiverReminderRequest } from '@zola/svc-web-api-ts-client';

import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';
import { EMAIL, REQUIRED } from '@zola/zola-ui/src/components/Form/util/validations';
import { createGiftGiverReminder } from '~/client/v1/giftGiverReminder';
import { getCookie } from '@zola-helpers/client/dist/es/util/storage';
import {
  GIFT_GIVER_COOKIE_KEY,
  updateGiftGiverCookie,
} from '~/components/common/modals/GiftGiverReminderModal/useGiftGiverReminderModal';

import COLORS3 from '@zola/zola-ui/src/styles/emotion/colors3';
import styles from './GiftGiverReminderModal.module.less';

type GiftGiverReminderModalProps = {
  forPublicRegistry?: boolean;
  onClose: () => void;
  registryObjectId: string;
};

/**
 * Modal for potential registry gift givers to opt in to a reminder email, shown over
 * 1. Public registry
 * 2. Multi-page wedding website registry page
 */
const GiftGiverReminderModal = ({
  forPublicRegistry = false,
  onClose,
  registryObjectId,
}: GiftGiverReminderModalProps): JSX.Element => {
  const dispatch = useDispatch();

  const [checkedEmailOptIn, setCheckedEmailOptIn] = useState(false);
  const [checkedTermsOfUse, setCheckedTermsOfUse] = useState(false);
  const [termsOfUseError, setTermsOfUseError] = useState('');

  const handleNoThanks = () => {
    // Explicit opt out - do not show modal on next visit
    updateGiftGiverCookie(getCookie(GIFT_GIVER_COOKIE_KEY), registryObjectId, 2);
    onClose();
  };

  const handleSetReminder = ({ email }: { email: string }) => {
    if (!checkedTermsOfUse) {
      setTermsOfUseError(' ');
      return;
    }

    createGiftGiverReminder({
      email,
      opt_in_marketing_emails: checkedEmailOptIn,
      registry_object_id: registryObjectId,
      store: 'WEDDING_REGISTRY' as unknown as WCreateGiftGiverReminderRequest.StoreEnum,
    })
      .then(() => {
        dispatch(
          toastsActions.positive({
            headline: "We've saved your preferences",
          })
        );
        onClose();
      })
      .catch(() => {
        onClose();
      });
  };

  return (
    <ModalV2
      dialogHeadingId="gift-giver-reminder-modal"
      lockBgScrolling
      onClose={onClose}
      rootId={forPublicRegistry ? 'root' : 'wedding-modal-root'}
      triggerRef={null}
    >
      <Form onSubmit={handleSetReminder}>
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit as () => void}>
            <ModalContent
              className={styles.giftGiverReminderModal}
              controls={
                <>
                  <ButtonV3
                    className={styles.reminderCta}
                    disabled={submitting}
                    onClick={handleSubmit as () => void}
                    size="large"
                  >
                    Set reminder
                  </ButtonV3>
                  <ButtonV3
                    disabled={submitting}
                    onClick={handleNoThanks}
                    size="large"
                    variant="secondary"
                  >
                    No, thanks
                  </ButtonV3>
                </>
              }
            >
              <div className={styles.iconContainer}>
                <GiftAddIcon
                  className={styles.icon}
                  color={COLORS3.COVE_100}
                  height={52}
                  width={52}
                />
              </div>
              <H.Title1 className={styles.header} id="gift-giver-reminder-modal" presentation="h3">
                Not ready to buy? Get a reminder two weeks before their wedding
              </H.Title1>
              <FinalFormInput
                disabled={submitting}
                id="email"
                isRequired
                label="Your email"
                name="email"
                placeholder="bob@example.com"
                type="email"
                validate={[EMAIL, REQUIRED]}
              />
              <div className={styles.checkboxFieldsContainer}>
                <CheckboxField
                  checked={checkedEmailOptIn}
                  className={styles.checkboxField}
                  disabled={submitting}
                  id="opt_in_marketing_emails"
                  label="I want to receive future promotion, offers and communications from Zola."
                  onChange={(e) => {
                    setCheckedEmailOptIn(e.currentTarget.checked);
                  }}
                />
                <CheckboxField
                  checked={checkedTermsOfUse}
                  className={styles.checkboxField}
                  disabled={submitting}
                  errorMessage={termsOfUseError}
                  id="terms_of_use"
                  label={
                    <>
                      I confirm that I have read and agreed to Zola’s{' '}
                      <LinkV2 href="/terms" noTextTransform target="_blank">
                        terms of use
                      </LinkV2>{' '}
                      and{' '}
                      <LinkV2 href="/privacy" noTextTransform target="_blank">
                        privacy policy
                      </LinkV2>
                      .<span className={styles.redText}>*</span>
                    </>
                  }
                  onChange={(e) => {
                    setTermsOfUseError(e.currentTarget.checked ? '' : ' ');
                    setCheckedTermsOfUse(e.currentTarget.checked);
                  }}
                />
              </div>
            </ModalContent>
          </form>
        )}
      </Form>
    </ModalV2>
  );
};

export { GiftGiverReminderModal };
