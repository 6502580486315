import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import loadable from '@loadable/component';

import { maybeFetchUser } from '~/actions/UserActions';
import { getUserContext } from '~/selectors/user';
import MobileNavV3 from '~/components/navV3/mobile/MobileNavV3';

import type { PrimaryNavIdentifier } from '@zola-helpers/client/dist/es/constants/navConstants';

import { useMobileNavDataListeners } from '~/hooks/useMobileNavDataListeners';

const MobileNav = loadable(
  () => import(/* webpackChunkName: "mobile-nav" */ '~/components/MobileNavV3'),
  { ssr: false }
);

const PostAuthMobileNavV3 = loadable(
  () =>
    import(
      /* webpackChunkName: "post-auth-mobile-nav-v3" */ '~/components/navV3/PostAuthMobile/PostAuthMobileNavV3'
    ),
  { ssr: false }
);

const WhichMobileNavToRender = (): JSX.Element => {
  const dispatch = useDispatch();
  const userContext = useSelector(getUserContext);

  const { primaryLinkId, secondaryLinkId, tertiaryLinkId, secondaryNavData, tertiaryNavData } =
    useMobileNavDataListeners();

  useEffect(() => {
    if (_isEmpty(userContext)) {
      dispatch(maybeFetchUser());
    }
  }, [dispatch, userContext]);

  if (userContext.is_guest) {
    // The pre-auth Mobile Nav
    return (
      <MobileNavV3
        primaryLinkId={primaryLinkId}
        secondaryLinkId={secondaryLinkId}
        secondaryNavData={secondaryNavData}
        tertiaryLinkId={tertiaryLinkId}
        tertiaryNavData={tertiaryNavData}
      />
    );
  }

  if (!_isEmpty(userContext) && !userContext.is_baby_registry_only) {
    return (
      <PostAuthMobileNavV3
        primaryLinkId={primaryLinkId}
        secondaryLinkId={secondaryLinkId}
        tertiaryLinkId={tertiaryLinkId}
        secondaryNavData={secondaryNavData}
        tertiaryNavData={tertiaryNavData}
      />
    );
  }

  return <MobileNav primaryLinkId={primaryLinkId as PrimaryNavIdentifier} />;
};

export default WhichMobileNavToRender;
